import * as React from "react";
import { Link } from "react-router";
import { Card, KeyboardEventManager, RefocusManager, Outliner, ProductLogo } from "@root/shared";
import { logger } from "@root/lib/telemetry";
import { ILinkItem } from "./link-item";

const styles = require("./login-shell.scss");
const classNames = require("classnames");

export interface LoginShellProps {
  linkItems?: ILinkItem[];
  externalLinkItems?: ILinkItem[];
  className?: string;
  styleName?: string;
  logo?: boolean;
  withoutPadding?: boolean;
  role?: string;
}

export class LoginShell extends React.Component<LoginShellProps, {}> {
  public render() {
    const { externalLinkItems, linkItems, logo, withoutPadding, role } = this.props;
    const shellStyle = classNames(styles["login-shell"], this.props.className);

    return (
      <KeyboardEventManager>
        <RefocusManager>
          <Outliner>
            <div className={shellStyle} role={role}>
              {logo ? <ProductLogo className={styles.logo} /> : null}
              <Card className={styles["card"]} withoutPadding={withoutPadding}>
                {this.props.children}
              </Card>
              <div className={styles["links-area"]}>
                {linkItems && linkItems.length > 0
                  ? linkItems.map((item, index) => (
                      <Link key={index} to={item.route}>
                        {item.title}
                      </Link>
                    ))
                  : null}
                {externalLinkItems && externalLinkItems.length > 0
                  ? externalLinkItems.map((item, index) => (
                      <a key={index} href={item.route} onClick={() => this._onClickLinkItemTrackEvent(item)}>
                        {item.title}
                      </a>
                    ))
                  : null}
              </div>
            </div>
          </Outliner>
        </RefocusManager>
      </KeyboardEventManager>
    );
  }

  private _onClickLinkItemTrackEvent(linkItem: ILinkItem): void {
    if (linkItem.shouldTrackClickEvent) {
      logger.info(`${linkItem.trackEventName}`);
    }
  }
}
