import * as React from "react";
import * as ReactDOM from "react-dom";
import { withRouter, WithRouterProps, Link } from "react-router";
/* tslint:disable */
import { Title, Text, Space, Checkbox, Paragraph, Autofocus, MessageBar } from "@root/shared";
/* tslint:enable */
import { Size, TextColor } from "@root/shared";
import { LoginShellFull } from "./login-shell-full";
import { ErrorBlock } from "@root/shared";
import { locationStore } from "../stores/location-store";
import { ILinkItem } from "./link-item";
import { observer } from "mobx-react";
import { NotificationType, SignupProps } from "@lib/common-interfaces";
import { LoginUrlHelper, ReturnUrls } from "./utils/login-url-helper";
import { LoginButtons } from "./login-buttons/login-buttons";
import { AppInvitationCard } from "client/login/app-invitation-card/app-invitation-card";
import { OrgInvitationCard } from "client/login/app-invitation-card/org-invitation-card";

const styles = require("./login.scss");
const { globalBanner } = require("../i18n/common.json");

@withRouter
@observer
export class Signup extends React.Component<WithRouterProps, {}> {
  public initProps?: SignupProps;

  constructor(props: WithRouterProps, state: any) {
    super(props, state);
  }

  private _form: any;

  public onSubmit(form: any, e: any) {
    (ReactDOM.findDOMNode(this._form) as any).submit();
  }

  public showErrorMessage(message: string) {
    return <ErrorBlock>{message}</ErrorBlock>;
  }

  private keepMeSignedInCheckboxChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      keepMeSignedIn: event.target.checked,
    });
    if (this.initProps) {
      this.initProps.keepMeSignedIn = event.target.checked;
    }
  };

  public UNSAFE_componentWillMount() {
    // Initialize the locationStore
    locationStore.setRouter(this.props.router);
    // Get initProps
    const { initProps = {} } = window as any;
    this.initProps = initProps;
  }

  public componentWillUnmount() {
    if (this.initProps) {
      this.initProps.message = undefined;
      this.initProps.email = undefined;
    }
  }

  public render() {
    const myWindow = window as any,
      currLocation = myWindow.location || {},
      returnUrls: ReturnUrls = LoginUrlHelper.buildReturnUrls(currLocation.search, this.initProps),
      bottomLinks: ILinkItem[] = [
        {
          route: "https://azure.microsoft.com/en-us/support/legal/",
          title: "Terms of use",
        },
      ],
      externalBottomLinks: ILinkItem[] = [
        {
          route: "https://aka.ms/appcenterprivacy",
          title: "Privacy",
        },
        {
          route: "https://status.appcenter.ms/",
          title: "Status",
        },
      ];

    const titleArea = (() => {
      return (
        <>
          {this.initProps?.appInvitation ? <AppInvitationCard {...this.initProps.appInvitation} /> : null}
          {this.initProps?.organizationInvitation ? <OrgInvitationCard {...this.initProps.organizationInvitation} /> : null}

          {globalBanner.enabled === "true" && (
            <MessageBar type={NotificationType.Warning} emphasizedBackground={true} className={styles["banner"]}>
              {globalBanner.textBeforeLink}{" "}
              <a href={globalBanner.link.url} target="blank">
                {globalBanner.link.displayedText}
              </a>{" "}
              {globalBanner.textAfterLink}
            </MessageBar>
          )}
          <Title size={Size.Medium} tabIndex={0}>
            Create your free account
          </Title>
        </>
      );
    })();

    const subtitleArea = (() => {
      return (
        <Text size={Size.Medium} tabIndex={0}>
          Log in with one of these services to get started.
        </Text>
      );
    })();

    const footerArea = (() => {
      return (
        <div>
          <Text size={Size.Small} spaceBelow={Space.Small}>
            By creating an account you accept our{" "}
            <a target="_blank" href="https://azure.microsoft.com/en-us/support/legal/">
              Terms of use
            </a>{" "}
            and{" "}
            <a target="_blank" href="https://aka.ms/appcenterprivacy">
              Privacy policy
            </a>
            .
          </Text>
        </div>
      );
    })();

    return (
      <Autofocus focus>
        <LoginShellFull
          linkItems={bottomLinks}
          externalLinkItems={externalBottomLinks}
          titleArea={titleArea}
          subtitleArea={subtitleArea}
          footerArea={footerArea}
          attemptingSignup={true}
          pageDetailForHelmetTitle={"Create Account"}
        >
          <div>
            <LoginButtons />
          </div>
          <Checkbox onChange={this.keepMeSignedInCheckboxChanged} checked={this.initProps?.keepMeSignedIn ?? false}>
            <Paragraph spaceBelow={Space.Small} size={Size.Medium} color={TextColor.Primary}>
              {"Keep me signed in"}
            </Paragraph>
          </Checkbox>
          <div className={styles["account-action-wrapper"]}>
            <Text color={TextColor.Black} size={Size.Medium}>
              <Paragraph size={Size.Medium} spaceBelow={Space.Medium}>
                Already have an account?{" "}
                <Link to={"/sign-in" + returnUrls.original} color={TextColor.Link} className={styles["account-action-link-wrapper"]}>
                  Sign in
                </Link>
              </Paragraph>
            </Text>
          </div>
        </LoginShellFull>
      </Autofocus>
    );
  }
}
