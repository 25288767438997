import * as React from "react";
import { Size, Card, Text, Paragraph, TextAlignment, TextColor, Space, Stretch, OrganizationIcon } from "@root/shared";
import { withTranslation, WithTranslation, Trans } from "react-i18next";
import { OrganizationInvitationProps } from "@lib/common-interfaces";

const styles = require("./app-invitation-card.scss");

export const OrgInvitationCard = withTranslation(["management"])(
  class OrgInvitationCard extends React.PureComponent<OrganizationInvitationProps & WithTranslation, {}> {
    public render() {
      const header = this.renderHeader();
      const icon = this.renderIcon();
      const text = this.renderText();

      return (
        <Card className={styles.appInvitationCard}>
          <Stretch vertical centered>
            {icon}
            <Paragraph size={Size.Medium} align={TextAlignment.Center}>
              {header}
            </Paragraph>
            <Text size={Size.Small} color={TextColor.Secondary}>
              Organization
            </Text>
            <Paragraph spaceAbove={Space.XSmall} size={Size.Small} align={TextAlignment.Center} color={TextColor.Secondary}>
              {text}
            </Paragraph>
          </Stretch>
        </Card>
      );
    }

    private renderHeader() {
      const { organizationDisplayName } = this.props;
      return (
        <Text bold size={Size.Medium}>
          {organizationDisplayName}
        </Text>
      );
    }

    private renderIcon() {
      const { organization } = this.props;

      return <OrganizationIcon className={styles.appIcon} size={48} organization={organization} />;
    }

    private renderText() {
      const { inviterName, organizationDisplayName } = this.props;

      return (
        <Trans i18nKey="management:invitationCard.orgInvitation" values={{ inviterName, organizationDisplayName }}>
          <Text size={Size.Small} color={TextColor.Secondary} bold>
            {inviterName}
          </Text>
        </Trans>
      );
    }
  }
);
