import * as React from "react";
import { Link } from "react-router";
import { KeyboardEventManager, RefocusManager, Outliner, Paragraph, Size, TextColor, Title } from "@root/shared";
import * as PageTimer from "@root/shared/page-timer";
import { ILinkItem } from "./link-item";
import { Copyright } from "./copyright";
import { LoginShellInitProps } from "@lib/common-interfaces/login-shell-init-props";
import { LoginLogo } from "./login-logo";
import TitleAndMetaHelmet from "./title-meta";
const classNames = require("classnames");
const styles = require("./login-shell-full.scss");
const loginHero = require("./login-bg.svg");

export interface LoginShellFullProps {
  linkItems?: ILinkItem[];
  externalLinkItems?: ILinkItem[];
  subtitleArea?: React.ReactNode;
  titleArea: React.ReactNode;
  footerArea?: React.ReactNode;
  attemptingSignup?: boolean;
  pageDetailForHelmetTitle?: string;
}

export class LoginShellFull extends React.Component<LoginShellFullProps, {}> {
  public initProps?: LoginShellInitProps;

  public UNSAFE_componentWillMount() {
    const { initProps } = window as any;
    this.initProps = initProps;
  }

  public render() {
    const { externalLinkItems, linkItems, subtitleArea, titleArea, footerArea, pageDetailForHelmetTitle } = this.props;
    const fromXamarinInsights = this.initProps?.fromXamarinInsights;
    const leftColClassName = classNames(styles["left-col"], { [styles["from-xamarininsights"]]: fromXamarinInsights });
    const leftColWrapperClassName = classNames(styles.leftColWrapper);
    const productName = "App Center";
    const header = `${productName} is mission control for apps`;
    const subHeader = `Continuously build, test, release, and monitor apps for every platform.`;

    return (
      <KeyboardEventManager>
        <RefocusManager>
          <Outliner>
            <PageTimer.Collector>
              <div className={styles["login-shell"]}>
                {<TitleAndMetaHelmet pageDetail={pageDetailForHelmetTitle} />}
                <div className={styles.banner}>
                  <div className={styles.bannerLogo}>
                    <LoginLogo className={styles.bannerIcon} />
                  </div>
                </div>

                <div className={leftColClassName}>
                  <div className={leftColWrapperClassName}>
                    <Title size={Size.Large} color={TextColor.White} className={styles.header} bold={false}>
                      {header}
                    </Title>
                    <Paragraph size={Size.Medium} color={TextColor.White} className={styles.subheader}>
                      {subHeader}
                    </Paragraph>
                  </div>
                  <img src={loginHero} className={styles["hero-img"]} width="100%" height="100%" alt="" role="presentation" />
                  <LoginLogo className={styles.loginLogo} />
                </div>

                <div className={styles["right-col"]}>
                  <div className={styles["right-col-wrapper"]}>
                    <div className={styles["login-form"]}>
                      <div className={styles["form-header"]}>
                        <div className={styles["appcenter-intro-header"]}>
                          <Title size={Size.Medium} bold={false}>
                            {header}
                          </Title>
                          <Paragraph className={styles["invitation-text"]} size={Size.Medium} color={TextColor.Secondary}>
                            {subHeader}
                          </Paragraph>
                        </div>
                        <Title size={Size.Small}>{titleArea}</Title>
                        <Paragraph className={styles["invitation-text"]} size={Size.Medium} color={TextColor.Secondary}>
                          {subtitleArea}
                        </Paragraph>
                      </div>

                      {this.props.children}
                    </div>

                    <div>
                      {footerArea}

                      <div className={styles["links-area"]}>
                        {linkItems && linkItems.length > 0
                          ? linkItems.map((item, index) => (
                              <Link key={index} to={item.route} target="_blank">
                                {item.title}
                              </Link>
                            ))
                          : null}
                        {externalLinkItems && externalLinkItems.length > 0
                          ? externalLinkItems.map((item, index) => (
                              <a key={index} href={item.route} target="_blank">
                                {item.title}
                              </a>
                            ))
                          : null}
                        <Paragraph size={Size.Small} color={TextColor.Secondary} className={styles["copyright-on-the-right"]}>
                          <Copyright />
                        </Paragraph>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </PageTimer.Collector>
          </Outliner>
        </RefocusManager>
      </KeyboardEventManager>
    );
  }
}
