import * as React from "react";
import { Helmet } from "react-helmet";
const productName = "App Center";

export interface TitleAndMetaHelmetProps {
  pageDetail?: string;
}

export default class TitleAndMetaHelmet extends React.Component<TitleAndMetaHelmetProps> {
  public render() {
    return (
      <Helmet
        title={`Visual Studio ${productName} | iOS, Android, Xamarin & React Native | ${this.props.pageDetail}`}
        meta={[
          {
            name: "description",
            content: `${productName} gives you faster release cycles, higher-quality apps and data to focus on what users want.`,
          },
        ]}
      />
    );
  }
}
