import * as React from "react";
import * as ReactDOM from "react-dom";
import { LoginShell } from "./login-shell";
import { ErrorBlock, PrimaryButton, Formsy, ProductLogo } from "@root/shared";
import { Grid, Row, Col, GridSpacing } from "@root/shared/grid";
import { CheckUsernameAvailableStore } from "../stores/check-username-available-store";
import { VALIDATION_ERRORS, VALIDATIONS } from "../lib/formsy/validations";
import { observer } from "mobx-react";

const styles = require("./update-username.scss");

@observer
export class UpdateUserName extends React.Component<{}, {}> {
  private _form: any;
  private _checkUsernameAvailableStore: CheckUsernameAvailableStore;

  constructor(props) {
    super(props);
    this._checkUsernameAvailableStore = new CheckUsernameAvailableStore();
  }

  public showErrorMessage(message: string) {
    if (message == null) {
      return null;
    }

    return <ErrorBlock>{message}</ErrorBlock>;
  }

  public onSubmit = (form: any, e: any) => {
    (ReactDOM.findDOMNode(this._form) as any).submit();
  };

  public render() {
    const errorString: string = "errorMessage=";
    const url: string = window.location.toString();
    let errorMessage: string | null = null;
    if (url.indexOf(errorString) > 0) {
      errorMessage = decodeURI(url.substr(url.indexOf(errorString) + errorString.length));
    }
    const initProps = (window as any).initProps || {};
    const currentUserName = initProps.username;
    const csrfToken = initProps.csrfToken;
    return (
      <LoginShell className={styles["shell"]}>
        <Grid rowSpacing={GridSpacing.XLarge}>
          <Row>
            <Col>
              <ProductLogo className={styles["logo"]} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Formsy.Form ref={(ref) => (this._form = ref)} key="updateusername" method="post" onValidSubmit={this.onSubmit}>
                <Grid>
                  <Row>
                    <Col>
                      <h1 className={styles["title"]}>Choose your username to start.</h1>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Formsy.InputWithAvailabilityCheck
                        groupClassName={styles.field}
                        name="username"
                        type="username"
                        availabilityMessageName="Username"
                        defaultValue={currentUserName}
                        checkDefaultValue={true}
                        autoCorrect="none"
                        autoCapitalize="none"
                        autoComplete="off"
                        spellCheck="false"
                        placeholder=""
                        isRequired
                        requiredError="Username is required"
                        available={this._checkUsernameAvailableStore.available}
                        onCheckAvailability={this._checkUsernameExists}
                        validations={VALIDATIONS.USER_NAME}
                        validationErrors={VALIDATION_ERRORS.USER_NAME}
                      />
                      <Formsy.Input type="hidden" name="_csrf" value={csrfToken} />
                    </Col>
                  </Row>
                  {errorMessage ? (
                    <Row>
                      <Col>{this.showErrorMessage(errorMessage)}</Col>
                    </Row>
                  ) : null}
                  <Row>
                    <Col>
                      <PrimaryButton type="submit">Choose username</PrimaryButton>
                    </Col>
                  </Row>
                </Grid>
              </Formsy.Form>
            </Col>
          </Row>
        </Grid>
      </LoginShell>
    );
  }

  private _checkUsernameExists = (username: string) => {
    this._checkUsernameAvailableStore.checkUsernameAvailable(username);
  };
}
