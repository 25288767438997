import * as React from "react";
import * as ReactDOM from "react-dom";
/* tslint:disable */
import { Formsy } from "@root/shared";
/* tslint:enable */
import { PrimaryButton } from "@root/shared";
import { Grid, Col, Row } from "@root/shared/grid";
import { LoginShell } from "../login-shell";
import { ILinkItem } from "../link-item";

const styles = require("./reset-password.scss");

export interface SendEmailForResetPasswordProps {
  // Nothing to add here yet.
}

export class SendEmailForResetPassword extends React.Component<SendEmailForResetPasswordProps, {}> {
  private _resetForm: any;
  private _bottomLinks: ILinkItem[] = [
    {
      route: "/sign-in",
      title: "Sign in instead",
    },
  ];

  private _handleSubmit() {
    (ReactDOM.findDOMNode(this._resetForm) as any).submit();
  }

  public render() {
    const { initProps } = window as any;
    const { csrfToken } = initProps;
    return (
      <LoginShell linkItems={this._bottomLinks}>
        <Formsy.Form key="login-form" method="post" ref={(ref) => (this._resetForm = ref)} onValidSubmit={() => this._handleSubmit()}>
          <input type="hidden" name="_csrf" value={csrfToken} />
          <Grid>
            <Row>
              <Col width={12}>
                <div className={styles["title"]}>Forgot your password?</div>
                <div className={styles["subtitle"]}>
                  Well, that happens to everyone. Just type your email address and we’ll help you out.
                </div>
              </Col>
            </Row>

            <Row>
              <Col width={12}>
                <Formsy.Input
                  name="email"
                  type="email"
                  defaultValue={null}
                  autoCorrect="none"
                  autoCapitalize="none"
                  autoComplete="off"
                  spellCheck="false"
                  placeholder="Email address"
                  isRequired
                  validations="isEmail"
                  autoFocus
                  requiredError="Email address is required."
                  validationError="Please enter a valid email address."
                />
              </Col>
            </Row>
            <Row>
              <Col width={12}>
                <PrimaryButton type="submit" formNoValidate>
                  Send reset email
                </PrimaryButton>
              </Col>
            </Row>
          </Grid>
        </Formsy.Form>
      </LoginShell>
    );
  }
}
