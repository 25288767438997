import * as React from "react";
import { Link } from "react-router";
import * as ReactDOM from "react-dom";
import {
  PrimaryButton,
  ButtonSize,
  Formsy,
  ErrorBlock,
  Size,
  Paragraph,
  TextColor,
  Title,
  Space,
  Checkbox,
  MessageBar,
} from "@root/shared";
import { LoginShellFull } from "./login-shell-full";
import { ILinkItem } from "./link-item";
import { LoginProps, NotificationType } from "@lib/common-interfaces";
import { LoginUrlHelper, ReturnUrls } from "./utils/login-url-helper";
import { LoginButtons } from "./login-buttons/login-buttons";
import { AppInvitationCard } from "client/login/app-invitation-card/app-invitation-card";
import { OrgInvitationCard } from "client/login/app-invitation-card/org-invitation-card";
import { LiveRegion } from "@root/shared";

const styles = require("./login.scss");
const { globalBanner } = require("../i18n/common.json");

export interface LoginState {
  keepMeSignedIn?: boolean;
  errorMessage?: string;
  signinWithEmail?: boolean;
  signinQuestionText?: string;
  signinAltText?: string;
  isFirstLoad?: boolean;
}

export class Login extends React.Component<{}, LoginState> {
  public initProps?: LoginProps;
  private _form: any;

  constructor(props) {
    super(props);
    this.state = {
      keepMeSignedIn: false,
      errorMessage: "",
      signinWithEmail: false,
      signinQuestionText: "Did you create an account directly in App Center?",
      signinAltText: "Sign in with email",
      isFirstLoad: true,
    };
  }

  private keepMeSignedInCheckboxChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      keepMeSignedIn: event.target.checked,
    });
    if (this.initProps) {
      this.initProps.keepMeSignedIn = event.target.checked;
    }
  };

  public validateForm = (values: any) => {
    const emailInput = this._form.inputs.email.input;
    const passwordInput = this._form.inputs.password.input;

    if (!values.email && !values.password) {
      emailInput.focus();
      this.setState({
        errorMessage: "Email and password are required.",
      });
    } else if (!values.email) {
      emailInput.focus();
      this.setState({
        errorMessage: "Email or username is required.",
      });
    } else if (!values.password) {
      passwordInput.focus();
      this.setState({
        errorMessage: "Password is required.",
      });
    }
  };

  public onSubmit = () => {
    (ReactDOM.findDOMNode(this._form) as any).submit();
  };

  public showErrorMessage(message: string) {
    return (
      <LiveRegion role="alert" active={true}>
        <ErrorBlock>{message}</ErrorBlock>
      </LiveRegion>
    );
  }

  public UNSAFE_componentWillMount() {
    const { initProps = {} } = window as any;
    this.initProps = initProps;
    this.setState({
      errorMessage: this.initProps?.message,
    });
  }

  public componentWillUnmount() {
    if (this.initProps) {
      this.initProps.message = undefined;
      this.initProps.email = undefined;
    }
  }

  private renderTitleArea = (): React.ReactNode => {
    return (
      <>
        {this.initProps?.appInvitation ? <AppInvitationCard {...this.initProps.appInvitation} /> : null}
        {this.initProps?.organizationInvitation ? <OrgInvitationCard {...this.initProps.organizationInvitation} /> : null}

        {globalBanner.enabled === "true" && (
          <MessageBar type={NotificationType.Warning} emphasizedBackground={true} className={styles["banner"]}>
            {globalBanner.textBeforeLink}{" "}
            <a href={globalBanner.link.url} target="blank">
              {globalBanner.link.displayedText}
            </a>{" "}
            {globalBanner.textAfterLink}
          </MessageBar>
        )}
        <Title size={Size.Medium}>Sign in</Title>
      </>
    );
  };

  private renderSubtitleArea = (): React.ReactNode => {
    const { signinWithEmail } = this.state;
    if (signinWithEmail) {
      return <div>Log in to your account with your username and password.</div>;
    }
    return <div>Log in to your account with one of these services.</div>;
  };

  public clearErrorMessage = () => {
    this.setState({ errorMessage: "" });
  };

  public toggleSigninChoice = () => {
    const signinWithEmail = this.state.signinWithEmail;
    signinWithEmail
      ? this.setState({
          signinWithEmail: false,
          signinQuestionText: "Did you create an account directly in App Center?",
          signinAltText: "Sign in with email",
        })
      : this.setState({
          signinWithEmail: true,
          signinQuestionText: "Did you create an account with an identity provider?",
          signinAltText: "Sign in with an identity provider",
        });
  };

  private renderSignInForm = () => {
    const { errorMessage } = this.state;
    const emailName = this.initProps?.email;
    const signinText = "Sign in";

    return (
      <Formsy.Form
        ref={(ref) => (this._form = ref)}
        key="login-form"
        method="post"
        onInvalidSubmit={this.validateForm}
        onValidSubmit={this.onSubmit}
        className={styles["login-form"]}
      >
        <div>
          <input name="_csrf" type="hidden" value={this.initProps?.csrfToken} />
          <input name="kmsi" type="hidden" value={`${this.initProps?.keepMeSignedIn}`} />

          <Formsy.Input
            groupClassName={styles.field}
            name="email"
            type="text"
            defaultValue={emailName}
            autoFocus={!emailName}
            autoCorrect="none"
            autoCapitalize="none"
            autoComplete="off"
            spellCheck="false"
            placeholder="Email or username"
            isRequired
            data-test-id="login-input-username"
          />
          <Formsy.Input
            groupClassName={styles.field}
            name="password"
            type="password"
            autoFocus={!!emailName}
            placeholder="Password"
            isRequired
            data-test-id="login-input-password"
          />
        </div>
        {errorMessage ? this.showErrorMessage(errorMessage) : null}
        <div className={styles["form-actions"]}>
          <div className={styles["form-actions-basic"]}>
            <PrimaryButton
              size={ButtonSize.Small}
              type="submit"
              formNoValidate
              className={styles["submit-button"]}
              data-test-id="login-button"
              onClick={this.clearErrorMessage}
            >
              {signinText}
            </PrimaryButton>
            <Paragraph size={Size.Medium} color={TextColor.Secondary}>
              <Link className={styles["forgot-password"]} to="/reset">
                Forgot password?
              </Link>
            </Paragraph>
          </div>
          <Checkbox onChange={this.keepMeSignedInCheckboxChanged} checked={this.state.keepMeSignedIn}>
            <Paragraph size={Size.Medium} color={TextColor.Primary}>
              {"Keep me signed in"}
            </Paragraph>
          </Checkbox>
        </div>
      </Formsy.Form>
    );
  };

  public render() {
    if (this.state.isFirstLoad && !!this.state.errorMessage && !this.initProps?.quickSignIn) {
      this.setState({
        keepMeSignedIn: false,
        signinQuestionText: "Did you create an account with an identity provider?",
        signinAltText: "Sign in with an identity provider",
        isFirstLoad: false,
        signinWithEmail: true,
      });
    }

    const myWindow = window as any,
      currLocation = myWindow.location || {},
      returnUrls: ReturnUrls = LoginUrlHelper.buildReturnUrls(currLocation.search, this.initProps),
      bottomLinks: ILinkItem[] = [
        {
          route: "https://azure.microsoft.com/en-us/support/legal",
          title: "Terms of use",
        },
      ],
      externalBottomLinks: ILinkItem[] = [
        {
          route: "https://aka.ms/appcenterprivacy",
          title: "Privacy",
        },
        {
          route: "https://status.appcenter.ms/",
          title: "Status",
        },
      ];

    const titleArea = this.renderTitleArea();
    const subtitleArea = this.renderSubtitleArea();
    const { signinWithEmail, errorMessage } = this.state;

    return (
      <div data-test-id="login-page" id="content" role="main">
        <LoginShellFull
          linkItems={bottomLinks}
          externalLinkItems={externalBottomLinks}
          titleArea={titleArea}
          subtitleArea={subtitleArea}
          pageDetailForHelmetTitle={"Sign In"}
        >
          <div>
            {!signinWithEmail ? (
              <>
                <LoginButtons />
                {errorMessage ? this.showErrorMessage(errorMessage) : null}
              </>
            ) : null}
            {signinWithEmail ? this.renderSignInForm() : null}
          </div>
          {!signinWithEmail ? (
            <Checkbox onChange={this.keepMeSignedInCheckboxChanged} checked={this.state.keepMeSignedIn}>
              <Paragraph spaceBelow={Space.Small} size={Size.Medium} color={TextColor.Primary}>
                {"Keep me signed in"}
              </Paragraph>
            </Checkbox>
          ) : null}
          <div className={styles["account-action-wrapper"]} data-test-id={"bottom-links"}>
            <Paragraph color={TextColor.Primary} size={Size.Medium} spaceBelow={Space.Medium}>
              {this.state.signinQuestionText}
              <Link
                to={"/sign-in" + window.location.search}
                onClick={this.toggleSigninChoice}
                color={TextColor.Link}
                className={styles["account-action-link-wrapper"]}
                data-test-id="login-with-credentials-link"
              >
                {this.state.signinAltText}
              </Link>
            </Paragraph>

            <Paragraph color={TextColor.Primary} size={Size.Medium} spaceBelow={Space.Medium}>
              New here?
              <Link
                to={"/create-account" + returnUrls.original}
                color={TextColor.Link}
                className={styles["account-action-link-wrapper"]}
              >
                Create a new account
              </Link>
            </Paragraph>
          </div>
        </LoginShellFull>
      </div>
    );
  }
}
