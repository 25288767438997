import * as React from "react";
import { LoginShell } from "../login-shell";
import { PrimaryButton } from "@root/shared";
import { Grid, Col, Row } from "@root/shared/grid";

const styles = require("./reset-password.scss");

export interface ResetPasswordEmailSentProps {
  // Nothing to add here yet.
}

export class ResetPasswordEmailSent extends React.Component<ResetPasswordEmailSentProps, {}> {
  public render() {
    return (
      <LoginShell>
        <Grid>
          <Row>
            <Col width={12}>
              <div className={styles["title"]}>We’ve sent you an email with instructions to reset your password.</div>
            </Col>
          </Row>

          <Row>
            <Col width={12}>
              <PrimaryButton onClick={() => ((window as any).location = "/")}>Okay</PrimaryButton>
            </Col>
          </Row>
        </Grid>
      </LoginShell>
    );
  }
}
