import { action, computed, observable } from "mobx";
import { DEPRECATED_DO_NOT_USE_SEE_DOC_COMMENT_ExternalDataStore, ExternalDataState } from "@root/lib/utils/external-data";
import { portalServer } from "@root/lib/http";

export interface AzureSubscriptionLinkConfig {
  accountId: string;
  accountType: string;
}

export interface AzureSubscriptionData {
  subscriptionId: string;
  tenantId: string;
  subscriptionName: string;
  accessToken?: string;
}

export interface SelectedAadSubscription {
  azureSubscriptionLinkConfig?: AzureSubscriptionLinkConfig;
  azureSubscriptionData?: AzureSubscriptionData;
}

export class LinkAadStore extends DEPRECATED_DO_NOT_USE_SEE_DOC_COMMENT_ExternalDataStore<string> {
  @observable
  public redirecting: boolean;

  @observable
  private _selectedAadSubscription?: SelectedAadSubscription;

  constructor() {
    super();
    this.state = ExternalDataState.Loaded;
    this.redirecting = false;
    this.resetAadSubscription();
  }

  @computed
  public get selectedAadSubscription(): SelectedAadSubscription | undefined {
    return this._selectedAadSubscription;
  }

  @computed
  public get azureSubscriptionLinkConfig(): AzureSubscriptionLinkConfig | undefined {
    return this.selectedAadSubscription?.azureSubscriptionLinkConfig;
  }

  @computed
  public get azureSubscriptionData(): AzureSubscriptionData | undefined {
    return this.selectedAadSubscription?.azureSubscriptionData;
  }

  @computed
  public get queryParams(): { [param: string]: string } {
    return this.hasSelectedAadSubscription
      ? {
          tenant_id: this.azureSubscriptionData?.tenantId || "",
          subscription_id: this.azureSubscriptionData?.subscriptionId || "",
          subscription_name: this.azureSubscriptionData?.subscriptionName || "",
          account_id: this.azureSubscriptionLinkConfig?.accountId || "",
          account_type: this.azureSubscriptionLinkConfig?.accountType || "",
        }
      : {};
  }

  @computed
  public get hasSelectedAadSubscription(): boolean {
    return typeof this.azureSubscriptionData === "object" && typeof this.azureSubscriptionLinkConfig === "object";
  }

  @action
  public setAadSubscription(selectedAadSubscription: SelectedAadSubscription): void {
    this._selectedAadSubscription = selectedAadSubscription;
  }

  @action
  public resetAadSubscription(): void {
    this._selectedAadSubscription = {
      azureSubscriptionLinkConfig: undefined,
      azureSubscriptionData: undefined,
    };
  }

  @action
  public redirect(
    azureSubscriptionLinkConfig: AzureSubscriptionLinkConfig | undefined,
    azureSubscriptionData: AzureSubscriptionData,
    redirectUrl: string
  ): void {
    const { accountId, accountType } = azureSubscriptionLinkConfig || { accountId: undefined, accountType: undefined };
    const { tenantId, subscriptionId, subscriptionName } = azureSubscriptionData;
    const isNewSubscription = accountId && accountType;
    redirectUrl = redirectUrl || "/apps";
    const subscriptionInfo = isNewSubscription
      ? `account_id=${accountId}&account_type=${accountType}&tenant_id=${tenantId}&subscription_id=${subscriptionId}&subscription_name=${encodeURIComponent(
          subscriptionName
        )}`
      : `tenant_id=${tenantId}&subscription_id=${subscriptionId}&subscription_name=${encodeURIComponent(subscriptionName)}`;
    const redirectUrlWithSubscriptionInfo =
      redirectUrl.search(/[?]/g) === -1 ? `${redirectUrl}?${subscriptionInfo}` : `${redirectUrl}&${subscriptionInfo}`;
    this.redirecting = true;
    window.location.href = redirectUrlWithSubscriptionInfo;
  }

  @action
  public linkAndRedirect(
    azureSubscriptionLinkConfig: AzureSubscriptionLinkConfig,
    azureSubscriptionData: AzureSubscriptionData,
    redirectUrl: string
  ): void {
    this.setAadSubscription({
      azureSubscriptionLinkConfig: azureSubscriptionLinkConfig,
      azureSubscriptionData: azureSubscriptionData,
    });
    this.linkAadSubscription().then(() => this.redirect(undefined, azureSubscriptionData, redirectUrl));
  }

  @action
  public linkAadSubscription(): Promise<void> {
    if (!this.hasSelectedAadSubscription) {
      return Promise.resolve();
    }

    return this.loadVoid(
      portalServer
        .post<any>("/aad/link_subscription", {
          body: { "AC-Authorization-ARM": this.azureSubscriptionData?.accessToken, ...this.queryParams },
          noBifrostToken: true,
        })
        .then((response: any) => {
          this.resetAadSubscription();
          return response;
        })
        .catch((error) => {
          throw error;
        })
    );
  }

  @action
  public clearFailureState() {
    this.state = ExternalDataState.Loaded;
  }
}

export const linkAadStore = new LinkAadStore();
