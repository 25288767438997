import * as Sentry from "@sentry/react";
import "../global-config";

/* tslint:disable */
import * as React from "react";
/* tslint:enable */
import * as ReactDOM from "react-dom";
import { Router, Route, browserHistory } from "react-router";
import { Login } from "./login";
import { Signup } from "./signup";
import { UpdateUserName } from "./update-username";
import { VerifyAccount } from "./verify-account";
import { CloseAccountStatus } from "./close-account-status";
import { ResetPassword } from "./reset-password/reset-password";
import { SendEmailForResetPassword } from "./reset-password/send-email-for-reset-password";
import { ResetPasswordEmailSent } from "./reset-password/reset-password-email-sent";
import { AppInvitation } from "./invitations/app-invitation";
import { OrganizationInvitation } from "./invitations/org-invitation";
import { appStore } from "../stores/app-store";
import { telemetry } from "../lib/telemetry";

import i18n from "../lib/i18n/i18n";
import { I18nextProvider } from "react-i18next";

import { AADSubscriptions } from "./aad-subscriptions";
import { Helmet } from "react-helmet";
import { DEFAULT_TITLE } from "@lib/constants/ui";

/**
 * Setup the telemetry context.
 * Need to save the AppStore context because http and appStore has a circular dependency
 */
telemetry.setAppStoreContext(appStore);

// The primary client-side router.
ReactDOM.render(
  <Sentry.ErrorBoundary>
    <I18nextProvider i18n={i18n}>
      <React.Suspense fallback={null}>
        <div>
          <Helmet defaultTitle={DEFAULT_TITLE} />
          <Router history={browserHistory}>
            <Route path="/sign-in" component={Login} />
            <Route path="/login" component={Login} />
            <Route path="/signup" component={Signup} />
            <Route path="/create-account" component={Signup} />
            <Route path="/create-account/updateusername" component={UpdateUserName} />
            <Route path="/user/:email/verify" component={VerifyAccount} />
            <Route path="/user/:email/verify/:token" component={VerifyAccount} />
            <Route path="/reset" component={SendEmailForResetPassword} />
            <Route path="/user/:email/reset" component={ResetPassword} />
            <Route path="/reset/done" component={ResetPasswordEmailSent} />
            <Route path="/invitations/app/:token" component={AppInvitation} />
            <Route path="/invitations/app/:token/join" component={AppInvitation} />
            <Route path="/invitations/org/:token" component={OrganizationInvitation} />
            <Route path="/auth/aad/subscriptions/callback" component={AADSubscriptions} />
            <Route path="/user/:email/set_password" component={ResetPassword} />
            <Route path="/close-account-status" component={CloseAccountStatus} />
          </Router>
        </div>
      </React.Suspense>
    </I18nextProvider>
  </Sentry.ErrorBoundary>,
  document.getElementById("app")
);
