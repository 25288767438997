import { Model, Store, ResourceRequest, ProxiedModel } from "@root/data/lib";
import { observable } from "mobx";
import { FetchError } from "@root/lib/http/fetch-error";
import { noop } from "lodash";
import { addMinutes, isBefore, parseISO } from "date-fns";

export enum AadTokenResource {
  AadGraph = "AadGraph",
  Arm = "Arm",
  MicrosoftGraph = "MicrosoftGraph",
}

export interface DeserializedAadToken {
  tenantId?: string;
  resource?: AadTokenResource;
  accessToken?: string;
  expirationTime?: string;
}

export class AadToken extends Model<DeserializedAadToken> implements DeserializedAadToken {
  @observable public tenantId?: string;
  @observable public resource?: AadTokenResource;
  @observable public accessToken?: string;
  @observable public expirationTime?: string;
}

export interface AadTokenQuery {
  tenantId: string;
  resource: AadTokenResource;
}

export function getAadTokenId(token: DeserializedAadToken | AadTokenQuery): string {
  return `${token.tenantId}-${token.resource}`;
}

export function getSignInWithAadUrl(): string {
  const signInUrl = `/auth/aad?original_url=${encodeURIComponent(window.location.pathname)}`;

  // Sign the user out first
  return `/logout?original_url=${encodeURIComponent(signInUrl)}`;
}

export function getRefreshTokenUrl(): string {
  // Needs more experimentation. The ultimate goal is to take the user through AAD sign in without interaction required.
  return getSignInWithAadUrl();
}

export function getAdminConsentUrl(query: AadTokenQuery): string {
  const consentUrl = `/auth/aad_admin_consent?tenant_id=${encodeURIComponent(query.tenantId)}&resource=${encodeURIComponent(
    query.resource
  )}&original_url=${encodeURIComponent(window.location.pathname)}`;
  // Sign the user out. Otherwise /auth/aad/callback starts subscription linking flow
  return `/logout?original_url=${encodeURIComponent(consentUrl)}`;
}

const expirationWindowInMinutes = 10;

export class AadTokenStore extends Store<DeserializedAadToken> {
  protected ModelClass = AadToken;

  private cache: { [aadTokenId: string]: DeserializedAadToken } = {};

  public fetchOneCached(aadTokenQuery: AadTokenQuery) {
    const aadTokenId = getAadTokenId(aadTokenQuery);
    if (
      this.cache[aadTokenId] &&
      isBefore(
        addMinutes(new Date(), expirationWindowInMinutes),
        // switched from parse to parseISO in 2.0
        parseISO(this.cache[aadTokenId].expirationTime!)
      )
    ) {
      return new ResourceRequest<ProxiedModel<DeserializedAadToken>, DeserializedAadToken>(
        Promise.resolve(this.cache[aadTokenId]),
        () => this.get(aadTokenId)!,
        noop
      );
    }

    return this.fetchOne(getAadTokenId(aadTokenQuery), aadTokenQuery).onSuccess((aadToken: DeserializedAadToken | undefined) => {
      this.cache[aadTokenId] = aadToken!;
    });
  }

  protected getResource(_: string, query?: AadTokenQuery): Promise<DeserializedAadToken> {
    return fetch(
      "/auth/aad/token?tenant_id=" + encodeURIComponent(query?.tenantId) + "&resource=" + encodeURIComponent(query?.resource),
      { credentials: "include" }
    ).then((response: Response) => {
      if (response.ok) {
        return response.json() as Promise<DeserializedAadToken>;
      } else {
        return (response.json() as Promise<DeserializedAadToken>).then((body) => {
          throw new FetchError(response, body);
        });
      }
    });
  }

  protected generateIdFromResponse(resource: DeserializedAadToken): string {
    return getAadTokenId(resource);
  }

  protected getModelId(model: AadToken): string {
    return getAadTokenId(model);
  }

  protected deserialize(serialized: DeserializedAadToken): DeserializedAadToken {
    return serialized;
  }
}

export const aadTokenStore = new AadTokenStore();
