import { RESPONSE_TYPES } from "@lib/common-interfaces";
import { observable, action } from "mobx";
import { apiGateway } from "@root/lib/http";
import { API, PARAM_KEYS } from "@lib/constants/api";
import { appStore } from "@root/stores/app-store";
import { locationStore } from "@root/stores/location-store";

export class AppInvitationStore {
  @observable
  public isActionPending: boolean = false;

  @observable
  public errorMessage?: string;

  constructor(private token: string | undefined, private ownerName: string | undefined, private appName: string | undefined) {}

  @action
  public accept() {
    this.doInvitationAction(API.ACCEPT_APP_INVITATION, "accept", () => {
      appStore
        .fetchApp({
          ownerName: this.ownerName,
          appName: this.appName,
        })
        .onSuccess((app) => {
          window.location.assign(locationStore.getUrlWithApp("", app));
        });
    });
  }

  @action
  public reject() {
    this.doInvitationAction(API.REJECT_APP_INVITATION, "reject", () => window.location.assign("/"));
  }

  private doInvitationAction(url: string, actionName: string, onSuccess: () => void) {
    this.isActionPending = true;

    apiGateway
      .post(url, {
        params: {
          [`${PARAM_KEYS.INVITE_TOKEN}`]: this.token,
        },
        responseType: RESPONSE_TYPES.TEXT,
      })
      .then(
        action(() => {
          this.errorMessage = undefined;
          onSuccess();
        })
      )
      .catch(
        action((error) => {
          console.error(error);
          this.isActionPending = false;
          this.errorMessage = `Unable to ${actionName} the invitation`;
        })
      );
  }
}
