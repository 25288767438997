import { observable } from "mobx";
import { Model, ProxiedModel, Store } from "@root/data/lib";
import { AadTokenQuery, AadTokenResource, aadTokenStore, DeserializedAadToken } from "@root/stores/aad-token-store";
import { flatten, sortBy } from "lodash";
import { portalServer } from "@root/lib/http";

export interface IAadSubscription {
  id: string;
  tenantId: string;
  displayName: string;
  state: string;
  isBillingAllowed: boolean;
  hasPermission: boolean;
  accessToken?: string;
}

export interface AadTenant {
  id: string;
  name: string;
}

// TODO: Move these interface to auto generated client from portal-server
export interface AadSubscriptionsResponse {
  subscriptions: IAadSubscription[];
  nextLink?: string;
}

export class AadSubscription extends Model<IAadSubscription> implements IAadSubscription {
  @observable public id!: string;
  @observable public tenantId!: string;
  @observable public displayName!: string;
  @observable public state!: string;
  @observable public isBillingAllowed!: boolean;
  @observable public hasPermission!: boolean;
  @observable public accessToken?: string;
}

export class AadSubscriptionStore extends Store<IAadSubscription> {
  protected ModelClass = AadSubscription;

  protected deserialize<K extends keyof AadSubscription>(
    serialized: AadSubscription,
    queryOrOptions?: any,
    foreignKey?: K,
    foreignKeyValue?: AadSubscription[K]
  ): AadSubscription | undefined {
    return serialized;
  }

  protected generateIdFromResponse(resource: AadSubscription, query?: any) {
    return resource.id;
  }

  protected getModelId(model: ProxiedModel<AadSubscription>): string {
    return model.id;
  }

  public async getCollection<K extends keyof AadSubscription>(
    query?: any,
    foreignKey?: K,
    foreignKeyValue?: AadSubscription[K]
  ): Promise<IAadSubscription[]> {
    const tenants: AadTenant[] = query.tenants;
    const promises = tenants.map(async (tenant) => {
      const aadTokenQuery: AadTokenQuery = {
        tenantId: tenant.id,
        resource: AadTokenResource.Arm,
      };

      try {
        const deserializedAadToken: DeserializedAadToken = await aadTokenStore.fetchOneCached(aadTokenQuery).promise;

        let subscriptions = await this.fetchSubscriptions(deserializedAadToken);
        let finalSubscriptionsList = subscriptions.subscriptions;

        while (subscriptions.nextLink) {
          subscriptions = await this.fetchSubscriptions(deserializedAadToken, subscriptions.nextLink);
          finalSubscriptionsList = finalSubscriptionsList.concat(subscriptions.subscriptions);
        }
        return finalSubscriptionsList;
      } catch (error) {
        console.warn(`Failed to fetch ARM token for tenant ${aadTokenQuery.tenantId}, ${error && (error as { code: string }).code}`);
        return null;
      }
    });
    return Promise.all(promises).then((arrayOfArray) => {
      return sortBy(flatten(arrayOfArray) || [], "displayName") as any[];
    });
  }

  private async fetchSubscriptions(deserializedAadToken: DeserializedAadToken, nextLink?: string): Promise<AadSubscriptionsResponse> {
    const tenantId = deserializedAadToken.tenantId;
    const params: { [key: string]: string | string[] } = nextLink
      ? { tenantId: tenantId || "", nextLink }
      : { tenantId: tenantId || "" };
    return await portalServer.get<AadSubscriptionsResponse>("/aad/subscriptions", {
      params: params,
      noBifrostToken: true,
    });
  }
}

export const aadSubscriptionStore = new AadSubscriptionStore();
