import * as React from "react";
import { PrimaryButton, Color, Spinner } from "@root/shared";
import { LoginShell } from "../../login/login-shell";
import { Grid, Col, Row, GridSpacing } from "@root/shared/grid";
import { ActionText, ErrorBlock, ProductLogo } from "@root/shared";
import { observer } from "mobx-react";

import { OrgInvitationStore } from "../../stores/org-invitation-store";
import { OrganizationInvitationProps } from "@lib/common-interfaces";

const styles = require("./app-invitation.scss");

interface IProps {
  organizationInvitation?: OrganizationInvitationProps;
  hasPendingInvitation?: boolean;
}

@observer
export class OrganizationInvitation extends React.Component<any, {}> {
  private _orgInvitationStore?: OrgInvitationStore;

  public UNSAFE_componentWillMount() {
    this.initProps = (window as any).initProps;

    if (!!this.initProps?.organizationInvitation) {
      this._orgInvitationStore = new OrgInvitationStore(
        this.initProps.organizationInvitation.token,
        this.initProps.organizationInvitation.organizationName
      );
    }
  }

  private initProps?: IProps;

  public showErrorMessage(message: string) {
    if (message == null) {
      return null;
    }

    return <ErrorBlock>{message}</ErrorBlock>;
  }

  public render() {
    if (this.initProps?.hasPendingInvitation && !!this.initProps.organizationInvitation) {
      return this.renderChoice(this.initProps.organizationInvitation);
    } else {
      return this.renderNoInvite();
    }
  }

  public renderChoice(props: OrganizationInvitationProps) {
    return (
      <LoginShell>
        <Grid rowSpacing={GridSpacing.XLarge}>
          <Row>
            <Col>
              <ProductLogo className={styles["logo"]} />
            </Col>
          </Row>
          <Row>
            <Col width={12} className={styles["invitation-text"]}>
              <strong>{props.inviterName}</strong> invited you to join the <strong>{props.organizationDisplayName}</strong>{" "}
              organization.
              <br />
              Would you like to join?
            </Col>
          </Row>
          {this._orgInvitationStore?.isActionPending ? (
            <Row>
              <Col width={12}>
                <Spinner />
              </Col>
            </Row>
          ) : (
            <Row>
              <Col width={12} className={styles["actions"]}>
                <PrimaryButton onClick={() => this._orgInvitationStore?.accept()} color={Color.Blue}>
                  Join organization
                </PrimaryButton>
                <ActionText className={styles["decline"]} onClick={() => this._orgInvitationStore?.reject()}>
                  Decline
                </ActionText>
              </Col>
            </Row>
          )}
          {this._orgInvitationStore?.errorMessage ? (
            <Row>
              <Col width={12}>{this.showErrorMessage(this._orgInvitationStore.errorMessage)}</Col>
            </Row>
          ) : null}
        </Grid>
      </LoginShell>
    );
  }

  public renderNoInvite() {
    return (
      <LoginShell>
        <Grid rowSpacing={GridSpacing.XLarge}>
          <Row>
            <Col>
              <ProductLogo className={styles["logo"]} />
            </Col>
          </Row>
          <Row>
            <Col width={12} className={styles["invitation-text"]}>
              The organization invitation is expired or invalid.
            </Col>
          </Row>
          <Row>
            <Col width={12}>
              <PrimaryButton onClick={() => window.location.assign("/")} color={Color.Blue}>
                Home
              </PrimaryButton>
            </Col>
          </Row>
        </Grid>
      </LoginShell>
    );
  }
}
