import { aadSubscriptionStore } from "@root/stores/aad-subscription-store";
import { tenantAADStore } from "./tenant-aad-store";
import { computed } from "mobx";
import { aadTokenStore, AadTokenResource, getAadTokenId } from "@root/stores/aad-token-store";

export class AadSubscriptionsUiStore {
  @computed
  public get isFetching() {
    return (
      aadTokenStore.isFetching(getAadTokenId({ tenantId: "common", resource: AadTokenResource.Arm })) ||
      tenantAADStore.isFetchingCollection ||
      aadSubscriptionStore.isFetchingCollection
    );
  }

  @computed get subscriptions() {
    return aadSubscriptionStore.resources;
  }

  @computed get tenants() {
    return tenantAADStore.resources;
  }

  public fetchAadToken() {
    return aadTokenStore.fetchOneCached({ tenantId: "common", resource: AadTokenResource.Arm });
  }

  public fetchTenants() {
    return tenantAADStore.fetchCollection();
  }

  public fetchSubscriptions(tenants) {
    return aadSubscriptionStore.fetchCollection({ tenants });
  }
}
