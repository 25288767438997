import * as React from "react";
import * as ReactDOM from "react-dom";
import {
  /* tslint:disable */
  Formsy,
  /* tslint:enable */
  PrimaryButton,
  ErrorBlock,
} from "@root/shared";
import { Grid, Col, Row } from "@root/shared/grid";
import { LoginShell } from "../login-shell";

const styles = require("./reset-password.scss");

export interface ResetPasswordProps {
  resetToken?: string;
  csrfToken?: string;
}

export class ResetPassword extends React.Component<ResetPasswordProps, {}> {
  private _resetForm: any;
  private initProps: ResetPasswordProps;

  constructor(props) {
    super(props);
    this.initProps = (window as any).initProps;
  }

  private _handleSubmit() {
    (ReactDOM.findDOMNode(this._resetForm) as any).submit();
  }

  // TODO: Need to replace this with whatever comes back from the designers for error messages
  public showErrorMessage(message: string | null) {
    if (message == null) {
      return null;
    }

    return <ErrorBlock>{message}</ErrorBlock>;
  }

  public render() {
    const errorString: string = "errorMessage=";
    const url: string = window.location.toString();
    const { csrfToken } = this.initProps;
    let errorMessage: string | null = null;
    if (url.indexOf(errorString) > 0) {
      errorMessage = decodeURI(url.substr(url.indexOf(errorString) + errorString.length));
    }
    return (
      <LoginShell>
        <Formsy.Form key="login-form" method="post" ref={(ref) => (this._resetForm = ref)} onValidSubmit={() => this._handleSubmit()}>
          <Formsy.Input type="hidden" name="reset_token" value={this.initProps.resetToken} />
          <Formsy.Input type="hidden" name="_csrf" value={csrfToken} />
          <Grid>
            <Row>
              <Col width={12}>
                <h1 className={styles["title"]}>Create a new password</h1>
              </Col>
            </Row>

            <Row>
              <Col width={12}>
                <Formsy.PasswordInput
                  name="new_password"
                  placeholder="Password"
                  isRequired
                  requiredError="New password cannot be empty"
                />
              </Col>
            </Row>

            <Row>
              <Col width={12}>
                <Formsy.Input
                  name="verify_password"
                  type="password"
                  defaultValue={null}
                  placeholder="Verify password"
                  validations="equalsField:new_password"
                  validationError="The new password and the verify password do not match."
                />
              </Col>
            </Row>
            <Row>
              <Col width={12}>
                {this.showErrorMessage(errorMessage)}
                <PrimaryButton type="submit">Change password</PrimaryButton>
              </Col>
            </Row>
          </Grid>
        </Formsy.Form>
      </LoginShell>
    );
  }
}
