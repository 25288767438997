// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._4095ik3Cx{max-width:360px}._427lGlJOx{height:40px}", "",{"version":3,"sources":["webpack://client/login/verify-account.scss"],"names":[],"mappings":"AAAA,YACE,eAAA,CAGF,YACE,WAAA","sourcesContent":[".shell {\n  max-width: 360px;\n}\n\n.logo {\n  height: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shell": "_4095ik3Cx",
	"logo": "_427lGlJOx"
};
export default ___CSS_LOADER_EXPORT___;
