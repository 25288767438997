import * as React from "react";
import * as ReactDOM from "react-dom";
import { withRouter, WithRouterProps } from "react-router";
import { PrimaryButton, ProductLogo } from "@root/shared";
import { Grid, Row, Col, GridSpacing } from "@root/shared/grid";
import { LoginShell } from "./login-shell";

const styles = require("./verify-account.scss");

@withRouter
export class VerifyAccount extends React.Component<WithRouterProps, {}> {
  public initProps: any;
  private _form: any;

  public UNSAFE_componentWillMount() {
    this.initProps = (window as any).initProps || {};
  }
  public componentWillUnmount() {
    this.initProps.message = null;
  }

  public onSubmit(form: any, e: any) {
    (ReactDOM.findDOMNode(this._form) as any).submit();
  }

  public showMessage(success: boolean, message: string) {
    return (
      <Row id="verification-message">
        <Col width={12}>
          <span data-test-id="verification-message">{message}</span>
        </Col>
      </Row>
    );
  }

  public showSignInButton(success: boolean, route: string) {
    if (success) {
      return (
        <Row>
          <Col width={12}>
            <PrimaryButton onClick={() => this.props.router.push(route)}>Sign in</PrimaryButton>
          </Col>
        </Row>
      );
    }
    return null;
  }

  public showResend(allowResend: boolean, email: string, csrfToken: string) {
    const url = `/user/${email}/verify`;
    if (allowResend) {
      return (
        <Row>
          <Col width={12}>
            <form method="post" action={url} ref={(ref) => (this._form = ref)}>
              <input type="hidden" name="_csrf" value={csrfToken} />
              <PrimaryButton data-test-id="resend-button" type="submit">
                Resend verification
              </PrimaryButton>
            </form>
          </Col>
        </Row>
      );
    }
  }

  public render() {
    const myWindow = window as any,
      currLocation = myWindow.location || {},
      originalURLQueryString = currLocation.search || "?original_url=/",
      route = "/sign-in" + originalURLQueryString;
    const { message, success, allowResend, email, csrfToken } = this.initProps;
    // Clear this out as soon as possible, because of issues with mount/unmount order between this
    // component and login.tsx
    this.initProps.message = null;

    return (
      <LoginShell className={styles.shell}>
        <Grid data-test-id="verify-account-page" rowSpacing={GridSpacing.XLarge}>
          <Row>
            <Col width={12}>
              <ProductLogo className={styles.logo} />
            </Col>
          </Row>
          {this.showMessage(success, message)}
          {this.showSignInButton(success, route)}
          {this.showResend(allowResend, email, csrfToken)}
        </Grid>
      </LoginShell>
    );
  }
}
