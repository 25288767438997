import { Store } from "@root/data/lib";
import { observable } from "mobx";
import { Model } from "@root/data/lib";
import { portalServer } from "@root/lib/http";
import { DeserializedAadToken } from "@root/stores/aad-token-store";

export interface ApiAuthTenant {
  id: string;
  name: string;
}
export interface DeserializedAADAuthTenant extends ApiAuthTenant {
  userId?: string;
}
export interface DeserializedAuthTenant extends ApiAuthTenant {
  subscriptionId?: string;
}

export class AuthTenant extends Model<DeserializedAuthTenant> implements DeserializedAuthTenant {
  @observable public id!: string;
  @observable public name!: string;
  @observable public type?: string;
  @observable public location?: string;
  @observable public subscriptionId?: string;
}

export interface AadTenantStoreQuery {
  aadToken: DeserializedAadToken;
}

export class TenantAADStore extends Store<DeserializedAADAuthTenant, ApiAuthTenant, AuthTenant, AadTenantStoreQuery> {
  protected ModelClass = AuthTenant;

  protected generateIdFromResponse(resource: ApiAuthTenant) {
    return resource.id;
  }

  protected getModelId(model: AuthTenant): string {
    return model.id;
  }

  protected async getCollection(): Promise<ApiAuthTenant[]> {
    return await portalServer.get("/aad/tenants", { noBifrostToken: true });
  }

  protected deserialize(serialized: ApiAuthTenant): DeserializedAADAuthTenant {
    return serialized;
  }
}

export const tenantAADStore = new TenantAADStore();
