import * as React from "react";
import { find } from "lodash";
import { observer } from "mobx-react";
import {
  Color,
  ErrorBlock,
  Formsy,
  MediaObject,
  PrimaryButton,
  Progress,
  SingleSelectActionItem,
  SingleSelectActionList,
  Size,
  Text,
  TextCell,
  TextColor,
} from "@root/shared";
import { LoginShell } from "./login-shell";
import { Col, Grid, GridSpacing, Row, RowCol } from "@root/shared/grid";
import { AzureSubscriptionData, AzureSubscriptionLinkConfig, linkAadStore } from "../stores/link-aad-store";
import { logger, telemetry } from "@root/lib/telemetry";
import { AadSubscriptionsUiStore } from "./stores/aad-subscriptions-ui-store";
import { aadSubscriptionStore, IAadSubscription } from "@root/stores/aad-subscription-store";
import { computed } from "mobx";

const styles = require("./aad-subscription.scss");

export interface AADSubscriptionsState {
  subscriptionId?: string;
  tenantId?: string;
  subscriptionName?: string;
  accessToken?: string;
}

@observer
export class AADSubscriptions extends React.Component<{}, Partial<AADSubscriptionsState>> {
  public initProps: any;
  private _form: any;
  private uiStore = new AadSubscriptionsUiStore();

  public state: AADSubscriptionsState = {
    subscriptionId: "",
    tenantId: "",
    subscriptionName: "",
    accessToken: "",
  };

  public showErrorMessage(message: string) {
    if (message == null) {
      return null;
    }

    return <ErrorBlock>{message}</ErrorBlock>;
  }

  @computed
  private get subscriptions() {
    const subscriptions: IAadSubscription[] = this.uiStore.subscriptions;
    logger.info("Subscriptions fetched", { numSubscriptions: subscriptions.length });
    return subscriptions;
  }

  public componentDidMount() {
    this.initProps = window.initProps || {};
    telemetry.setUserId(this.initProps.accountId);
    this.uiStore.fetchTenants().onSuccess((tenants) => {
      this.uiStore.fetchSubscriptions(tenants).onSuccess(() => {
        this.selectOnlySubscription();
      });
    });
  }

  private selectOnlySubscription() {
    const subscriptions = this.subscriptions;
    if (
      subscriptions.length === 1 &&
      subscriptions[0].hasPermission &&
      subscriptions[0].state &&
      subscriptions[0].state.toLowerCase() === "enabled"
    ) {
      const billingAllowed = this.initProps.isBilling ? subscriptions[0].isBillingAllowed : true;
      if (billingAllowed) {
        this.selectSubscription(subscriptions[0].id);
      }
    }
  }

  public onSubmit = (formValues: any, e: any) => {
    const eventName = this.beaconName
      ? `${this.beaconName}/aad/linkSubscription/connectSubscription/clicked`
      : "Connecting subscription";
    logger.info(eventName, { subscriptionId: `${formValues.subscription_id}`, accountId: `${this.initProps.accountId}` });
    const azureSubLinkConfig: AzureSubscriptionLinkConfig = {
      accountId: this.initProps.accountId,
      accountType: this.initProps.accountType,
    };
    const azureSubscriptionData: AzureSubscriptionData = {
      tenantId: formValues.tenant_id,
      subscriptionId: formValues.subscription_id,
      subscriptionName: formValues.subscription_name,
      accessToken: formValues.access_token,
    };

    linkAadStore.linkAndRedirect(azureSubLinkConfig, azureSubscriptionData, this.initProps.originalUrl);
  };

  public render() {
    const { subscriptionId, subscriptionName, accessToken } = this.state;
    const subscriptions = this.subscriptions;
    const errorString: string = "errorMessage=";
    const url: string = window.location.toString();
    const isConnecting = linkAadStore.isPending || linkAadStore.redirecting;
    const hasSubscriptions = this.subscriptions && this.subscriptions.length > 0;
    const createNewSubscriptionUrl: string = "https://portal.azure.com/#blade/Microsoft_Azure_Billing/BillingMenuBlade/Overview";
    let errorMessage: string | null = null;

    if (aadSubscriptionStore.collectionFetchError) {
      errorMessage = "There was an error retrieving your subscriptions. Please try again later.";
    }

    // check url for errors.
    if (url.indexOf(errorString) > 0) {
      errorMessage = decodeURI(url.substr(url.indexOf(errorString) + errorString.length));
    }

    // check store for errors
    if (linkAadStore.isFailed) {
      errorMessage = "Linking subscription failed. ";
      if (linkAadStore.error) {
        const error = linkAadStore.error as any;
        if (error.body && error.body.error) {
          errorMessage += error.body.error.message;
        } else {
          errorMessage += linkAadStore.error.message;
        }
      }
    }

    // insert placeholder loading skeletons
    const placeholders: JSX.Element[] = [];
    for (let i = 0; i < 3; i++) {
      placeholders.push(
        <SingleSelectActionItem disabled={true} key={i} value="">
          <Col width={6}>
            <MediaObject skeleton textOnly>
              <TextCell className={styles.placeholder} />
            </MediaObject>
          </Col>
          <Col width={6}>
            <MediaObject skeleton textOnly>
              <TextCell className={styles.placeholder} />
            </MediaObject>
          </Col>
        </SingleSelectActionItem>
      );
    }

    return (
      <LoginShell role="main" logo className={styles.wrapper} withoutPadding>
        <Progress loading={this.uiStore.isFetching} color={Color.Blue} />
        <Grid rowSpacing={GridSpacing.Large} bordered padded>
          {hasSubscriptions ? (
            <Row>
              <Col width={12}>
                <Text size={Size.Medium} bold>
                  Select a subscription
                </Text>
              </Col>
            </Row>
          ) : null}
          <Row>
            <Col>
              <Formsy.Form ref={(ref) => (this._form = ref)} key="select" method="get" onValidSubmit={this.onSubmit}>
                <Formsy.Input type="hidden" name="tenant_id" value={this.state.tenantId} />
                <Formsy.Input type="hidden" name="subscription_name" value={subscriptionName} />
                <Formsy.Input type="hidden" name="subscription_id" value={subscriptionId} />
                <Formsy.Input type="hidden" name="access_token" value={accessToken} />
              </Formsy.Form>
              {this.uiStore.isFetching ? (
                <SingleSelectActionList name="loading_skeleton">{placeholders}</SingleSelectActionList>
              ) : hasSubscriptions ? (
                <div className={styles.list}>
                  <SingleSelectActionList name="subcription" value={subscriptionId} onChange={this.selectSubscription}>
                    {subscriptions.map((subscription) => {
                      const isEnabled = subscription.state && subscription.state.toLowerCase() === "enabled";
                      const billingAllowed = this.initProps.isBilling ? subscription.isBillingAllowed : true;
                      const cannotUseSubscription = !subscription.hasPermission || !isEnabled || !billingAllowed;
                      return (
                        <SingleSelectActionItem disabled={cannotUseSubscription} key={subscription.id} value={subscription.id}>
                          <Col width={6} className={styles.name}>
                            <Text ellipsize size={Size.Medium}>
                              {subscription.displayName}
                            </Text>
                          </Col>
                          <Col width={6} className={styles.id}>
                            <MediaObject textOnly>
                              <Text ellipsize size={Size.Small} color={TextColor.Secondary}>
                                {subscription.id}
                              </Text>
                              {cannotUseSubscription ? (
                                <Text ellipsize size={Size.Small} color={TextColor.Secondary}>
                                  {!subscription.hasPermission
                                    ? "You don’t have permission to use this"
                                    : !isEnabled
                                    ? "This subscription isn’t enabled"
                                    : "This subscription isn't allowed for billing"}
                                </Text>
                              ) : null}
                            </MediaObject>
                          </Col>
                        </SingleSelectActionItem>
                      );
                    })}
                  </SingleSelectActionList>
                </div>
              ) : (
                <Text size={Size.Medium}>You do not have any subscriptions</Text>
              )}
              {errorMessage ? this.showErrorMessage(errorMessage) : null}
            </Col>
          </Row>
          <RowCol>
            <div className={styles.actions}>
              <a href={createNewSubscriptionUrl} onClick={this.trackNewSubscriptionEvent}>
                <Text size={Size.Medium} color={TextColor.Link}>
                  Create new subscription
                </Text>
              </a>
              <PrimaryButton progress={isConnecting} disabled={!subscriptionId} onClick={() => this._form.submit()}>
                {isConnecting ? "Connecting" : "Connect"}
              </PrimaryButton>
            </div>
          </RowCol>
        </Grid>
      </LoginShell>
    );
  }

  public trackNewSubscriptionEvent = (): void => {
    const eventName = this.beaconName ? `${this.beaconName}/aad/linkSubscription/createSubscription/clicked` : "Create subscription";
    logger.info(eventName);
  };

  public selectSubscription = (subscriptionId: string): void => {
    // find the selected sub
    const subscription = find(this.subscriptions, { id: subscriptionId });
    linkAadStore.clearFailureState();

    this.setState({
      subscriptionId: subscription.id,
      tenantId: subscription.tenantId,
      subscriptionName: subscription.displayName,
      accessToken: subscription.accessToken,
    });
  };

  private get beaconName(): string {
    // 0: /orgs/{orgName}/apps/{appName}/{beaconName}
    // 1: orgName
    // 2: appName
    // 3: beaconName
    const beaconRegex = new RegExp("/orgs/([^/]+)/apps/([^/]+)/([^/]+)");
    const beaconMatch = this.initProps.originalUrl ? this.initProps.originalUrl.match(beaconRegex) : undefined;
    return beaconMatch ? beaconMatch[3] : undefined;
  }
}
