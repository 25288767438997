import * as React from "react";
import { Size, Card, Text, Paragraph, TextAlignment, TextColor, Space, AppIcon, Stretch, OrganizationIcon } from "@root/shared";
import { APP_OWNER_TYPES } from "@lib/common-interfaces";
import { withTranslation, WithTranslation } from "react-i18next";
import { AppInvitationProps } from "@lib/common-interfaces";

const styles = require("./app-invitation-card.scss");

export const AppInvitationCard = withTranslation(["management"])(
  class AppInvitationCard extends React.PureComponent<AppInvitationProps & WithTranslation, {}> {
    public render() {
      const { t, app } = this.props;
      const header = this.renderHeader();
      const appsCount = this.renderAppsCount();
      const icon = this.renderIcon();
      const text = this.renderText();

      return (
        //
        // if app exists:
        //                  The App for iOS
        // John Doe invited you to join The App for iOS on App Center.
        //
        //
        // else:
        //                     Microsoft
        //                      14 apps
        // John Doe from Microsoft invited you to test 4 apps on App Center
        //

        <Card className={styles.appInvitationCard}>
          <Stretch vertical centered>
            {icon}
            <Paragraph size={Size.Medium} align={TextAlignment.Center}>
              {header}
            </Paragraph>
            <Text size={Size.Small} color={TextColor.Secondary}>
              {appsCount}
            </Text>
            <Paragraph align={TextAlignment.Center} spaceAbove={0} spaceBelow={0} size={Size.Small} color={TextColor.Secondary}>
              {app?.owner && app.owner.type === APP_OWNER_TYPES.ORG
                ? t("management:invitationCard.byOwner", { appOwnerName: app.owner.display_name })
                : null}
            </Paragraph>
            <Paragraph spaceAbove={Space.XSmall} size={Size.Small} align={TextAlignment.Center} color={TextColor.Secondary}>
              {text}
            </Paragraph>
          </Stretch>
        </Card>
      );
    }

    private get isDistributionSharedGroupsInvitationsEnabled(): boolean {
      return process.env.NODE_ENV !== "production"; // TODO: Remove feature flag after bug bash
    }

    private renderHeader() {
      const { t, app, organization } = this.props;
      if (app) {
        return (
          <>
            <Text bold size={Size.Medium}>
              {app.display_name}
            </Text>{" "}
            {t("management:invitationCard.forOperatingSystem", { appOs: app.os })}
          </>
        );
      }

      return this.isDistributionSharedGroupsInvitationsEnabled ? (
        <Text bold size={Size.Medium}>
          {organization?.display_name}
        </Text>
      ) : null;
    }

    private renderAppsCount() {
      const { t, appCount } = this.props;
      if (appCount && this.isDistributionSharedGroupsInvitationsEnabled) {
        return t("management:invitationCard.appsCount", { count: appCount });
      }

      return null;
    }

    private renderIcon() {
      const { app, organization } = this.props;
      if (app) {
        return <AppIcon className={styles.appIcon} app={app} size={48} />;
      }

      return this.isDistributionSharedGroupsInvitationsEnabled ? (
        <OrganizationIcon className={styles.appIcon} size={48} organization={organization} />
      ) : null;
    }

    private renderText() {
      const { t, app, inviterName, inviteType, organization, appCount } = this.props;
      const doWhat = inviteType === "tester" ? t("management:invitationCard.doWhatTest") : t("management:invitationCard.doWhatJoin");
      let invitationText = "";

      if (app) {
        if (app.owner && app.owner.type === APP_OWNER_TYPES.ORG) {
          invitationText = t("management:invitationCard.invitationTextFromOrg", {
            orgName: app.owner?.display_name,
            doWhat,
            appDisplayName: app.display_name,
            appOs: app.os,
          });
        } else {
          invitationText = t("management:invitationCard.invitationTextFromNone", {
            doWhat,
            appDisplayName: app.display_name,
            appOs: app.os,
          });
        }
      } else if (this.isDistributionSharedGroupsInvitationsEnabled) {
        invitationText = t("management:invitationCard.invitationTextToTestAppsInGroup", {
          orgName: organization?.display_name,
          count: appCount,
        });
      }

      return (
        <>
          <Text size={Size.Small} color={TextColor.Secondary} bold>
            {inviterName}
          </Text>{" "}
          {invitationText}
        </>
      );
    }
  }
);
