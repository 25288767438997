import { LoginProps } from "@lib/common-interfaces";

export interface ReturnUrls {
  original: string;
  github: string;
  aad: string;
  facebook: string;
  google: string;
}

export class LoginUrlHelper {
  public static buildReturnUrls(originalUrl: string, props: LoginProps | undefined): ReturnUrls {
    const appInvitationReturnUrl =
        props?.appInvitation && props.appInvitation.inviteType !== "tester"
          ? `?original_url=/invitations/app/${props.appInvitation.token}&app_invitation=${props.appInvitation.token}`
          : null,
      organizationInvitationReturnUrl = !!props?.organizationInvitation
        ? `?original_url=/invitations/org/${props.organizationInvitation.token}&organization_invitation=${props.organizationInvitation.token}`
        : null;

    const invitationUrl = appInvitationReturnUrl || organizationInvitationReturnUrl || originalUrl;
    let kmsi = "";
    if (props?.keepMeSignedIn !== undefined) {
      kmsi = (invitationUrl ? "&" : "?") + (props?.keepMeSignedIn ? "kmsi=true" : "kmsi=false");
    }
    const urls: ReturnUrls = {
      original: originalUrl,
      github: "/auth/github" + invitationUrl + kmsi,
      aad: "/auth/aad" + invitationUrl + kmsi,
      facebook: "/auth/facebook" + invitationUrl + kmsi,
      google: "/auth/google" + invitationUrl + kmsi,
    };

    return urls;
  }
}
