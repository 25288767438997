import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { Copyright } from "./copyright";
import titleAndMetaHelmet from "./title-meta";
import { KeyboardEventManager, RefocusManager, Outliner, ProductLogo, Paragraph, Size, TextColor, Title } from "@root/shared";

const styles = require("./close-account-status.scss");

@withRouter
export class CloseAccountStatus extends React.Component<RouteComponentProps<any, any>, {}> {
  componentDidMount() {
    window.history.pushState(null, "", null);
    window.onpopstate = function () {
      window.location.href = "/";
    };
  }

  public render() {
    return (
      <KeyboardEventManager>
        <RefocusManager>
          <Outliner>
            <div className={styles["container"]}>
              {titleAndMetaHelmet}
              <div className={styles["logo"]}>
                <a href={"/"}>
                  <ProductLogo invert />
                </a>
              </div>
              <div className={styles["content"]}>
                <Title
                  invert
                  size={Size.Small}
                  color={TextColor.Primary}
                  className={styles["title"]}
                  role="status"
                  aria-live="assertive"
                >
                  Your account has been closed.
                </Title>
              </div>
              <Paragraph size={Size.Small} color={TextColor.Primary} invert className={styles["copyright"]}>
                <Copyright />
              </Paragraph>
            </div>
          </Outliner>
        </RefocusManager>
      </KeyboardEventManager>
    );
  }
}
