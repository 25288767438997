import * as React from "react";
import { PrimaryButton, Color, IconName, Text, Size, ButtonSize } from "@root/shared";
import { LoginUrlHelper, ReturnUrls } from "../utils/login-url-helper";

const styles = require("./login-buttons.scss");
const classNames = require("classnames");

export interface LoginButtonsProps {
  // Nothing to add here yet.
}

export class LoginButtons extends React.Component<LoginButtonsProps, {}> {
  public render() {
    const myWindow = window as any;
    const { initProps = {} } = myWindow;
    const currLocation = myWindow.location || {};
    const returnUrls: ReturnUrls = LoginUrlHelper.buildReturnUrls(currLocation.search, initProps);

    return (
      <div className={styles["external-logins"]}>
        {this.githubButton(returnUrls.github)}
        {this.microsoftButton(returnUrls.aad)}
        <div>
          {this.facebookButton(returnUrls.facebook)}
          {this.googleButton(returnUrls.google)}
        </div>
      </div>
    );
  }

  private facebookButton(returnUrl: string): JSX.Element {
    const buttonClassName = classNames(styles.field, styles["padding-left-m"]);
    return (
      <PrimaryButton
        alignLeft
        className={buttonClassName}
        color={Color.LightGray}
        href={returnUrl}
        size={ButtonSize.Large}
        icon={IconName.Facebook}
      >
        <Text size={Size.Large}>Facebook</Text>
      </PrimaryButton>
    );
  }

  private googleButton(returnUrl: string): JSX.Element {
    const buttonClassName = classNames(styles.field, styles["padding-left-m"]);
    return (
      <PrimaryButton
        alignLeft
        className={buttonClassName}
        color={Color.LightGray}
        href={returnUrl}
        size={ButtonSize.Large}
        icon={IconName.Google}
      >
        <Text size={Size.Large}>Google</Text>
      </PrimaryButton>
    );
  }

  private githubButton(returnUrl: string): JSX.Element {
    const githubButtonClassName = classNames(styles.field, styles["padding-left-m"]);
    return (
      <PrimaryButton
        alignLeft
        className={githubButtonClassName}
        href={returnUrl}
        color={Color.LightGray}
        size={ButtonSize.Large}
        icon={IconName.GitHub}
      >
        <Text size={Size.Large}>GitHub</Text>
      </PrimaryButton>
    );
  }

  private microsoftButton(returnUrl: string): JSX.Element {
    const microsoftButtonClassName = classNames(styles.field, styles["padding-left-m"]);
    return (
      <PrimaryButton
        alignLeft
        className={microsoftButtonClassName}
        color={Color.LightGray}
        href={returnUrl}
        size={ButtonSize.Large}
        icon={IconName.Microsoft}
      >
        <Text size={Size.Large}>Personal, Company or School</Text>
      </PrimaryButton>
    );
  }
}
